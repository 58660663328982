<script setup lang="ts">
import { UI05Toggle } from 'ui-05'
import type Question from '@/type/Question'

interface Props {
  list: Question[]
  limit?: number
}

const props = defineProps<Props>()

const isActiveAll = ref(false)
const questionsLimit = (props.limit && props.limit < props.list.length) ? ref(props.limit) : ref(props.list.length)
const questionsList = ref(props.list.slice(0, questionsLimit.value))

function onClickShowAll() {
  questionsList.value = props.list.slice(0, props.list.length)
  isActiveAll.value = true
}
</script>

<template>
  <div class="user-question__wrapper">
    <div
      v-for="(item, i) in questionsList"
      :key="i"
      class="user-question"
    >
      <UI05Toggle>
        <template #default="{ onClick, isActive }">
          <div
            class="user-question__title"
            :class="[{ 'user-question__title_active': isActive }]"
            @click="onClick"
          >
            <span class="user-question__text">{{ item.question }}</span>
          </div>
          <p v-if="isActive" class="user-question__desc">
            {{ item.answer }}
          </p>
        </template>
      </UI05Toggle>
    </div>
    <div
      v-if="!isActiveAll && questionsLimit !== props.list.length"
      class="user-question__show-all"
      @click="onClickShowAll"
    >
      <span>Посмотреть все вопросы</span>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.user-question {
  padding: 18px 0;
  border-top: 1px solid #F3F3F3;
  border-bottom: 1px solid #F3F3F3;

  &__text {
    max-width: calc(100% - 40px);
  }

  &__show-all {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    user-select: none;
    border-top: 1px solid #F3F3F3;
    border-bottom: 1px solid #F3F3F3;

    &:hover {
      color: #00B0B8;
    }
  }

  &__title {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 36px;
    font-size: 22px;
    font-weight: 400;
    line-height: 140%;
    cursor: pointer;
    user-select: none;

    &:after {
      position: absolute;
      right: 0;
      width: 36px;
      height: 36px;
      content: '';
      background-image: svg-load('./asset/expand.svg', fill=transparent);
      background-size: 36px;
      transition: transform 0.3s ease, background-image 0.3s ease;
      transform: rotate(0);
    }

    &:hover {

      &:after {
        background-image: svg-load('./asset/expand-red.svg', fill=#00B0B8);
      }
    }

    &_active {
      margin-bottom: 15px;

      &:after {
        background-image: svg-load('./asset/expand-red.svg', fill=#00B0B8);
        transform: rotate(45deg);
      }
    }
  }

  &__desc {
    max-width: 500px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: #5E5E5E;
  }

  @media (max-width: 800px) {

    &__title {
      font-size: 18px;
    }

    &__show-all {
      font-size: 20px;
    }
  }
}
</style>
